* {
  margin:0;
  padding:0;
  border:0;
  font:inherit;
  box-sizing: border-box;
  font-family: "SSR";
  position: relative;
  overflow-x: hidden;
}

@font-face {
font-family: "SSR";
src: local("SRGSSRTypeText"),
 url("./fonts/SRGSSRTypeText-Heavy.ttf") format("truetype");
font-weight: 900;
}
@font-face {
font-family: "SSR";
src: local("SRGSSRTypeText"),
 url("./fonts/SRGSSRTypeText-Bold.ttf") format("truetype");
font-weight: 700;
}
@font-face {
font-family: "SSR";
src: local("SRGSSRTypeText"),
 url("./fonts/SRGSSRTypeText-Medium.ttf") format("truetype");
font-weight: 500;
}
@font-face {
font-family: "SSR";
src: local("SRGSSRTypeText"),
 url("./fonts/SRGSSRTypeText-Regular.ttf") format("truetype");
font-weight: 400;
}
@font-face {
font-family: "SSRSerif";
src: local("SRGSSRTypeSerifText"),
 url("./fonts/SRGSSRTypeSerifText-Light.ttf") format("truetype");
font-weight: 400;
}
@font-face {
font-family: "SSRSerif";
src: local("SRGSSRTypeSerifText"),
 url("./fonts/SRGSSRTypeSerifText-Medium.ttf") format("truetype");
font-weight: 500;
}
@font-face {
font-family: "SSRSerif";
src: local("SRGSSRTypeSerifText"),
 url("./fonts/SRGSSRTypeSerifText-Regular.ttf") format("truetype");
font-weight: 600;
}
